var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{key:'A' + _vm.grouping.length,attrs:{"items":_vm.grouping,"fields":_vm.fields},scopedSlots:_vm._u([{key:"building_type_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.objects.building_types[item.building_type_id].name))])]}},{key:"property_unit_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.objects.property_units[item.property_unit_id].name))])]}},{key:"owner",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.objects.contacts[item.owner_id].name))])]}},{key:"options",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('CButton',{on:{"click":function($event){return _vm.actionGroup(index)}}},[_c('CIcon',{attrs:{"name":_vm.icon}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
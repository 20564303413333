<template>
  <div @keydown="onChange" @click="onChange" @change="onChange">
    <CRow v-if="!index" class="no-gutters">
      <CCol col="3" />
      <CCol col="4">{{ $t('Contact') }}</CCol>
      <CCol col="4">{{ $t('Type') }}</CCol>
      <CCol col="1" />
    </CRow>
    <CRow class="no-gutters">
      <CCol col="3" />
      <CCol col="4">
        <AMultiSelect :disabled="is_owner" :horizontal="{ input: 'col-sm-12' }" :name="`contacts.$each.${index}.property_contact_id`" v-model="state.property_contact_id" :options="available_contacts" :isValid="isValid('property_contact_id')" :errors="getErrors('property_contact_id')" />
      </CCol>
      <CCol col="4">
        <AMultiSelect :disabled="is_owner" :horizontal="{ input: 'col-sm-12' }" :name="`contacts.$each.${index}.type_id`" v-model="state.type_id" :options="available_types" :isValid="isValid('type_id')" :errors="getErrors('type_id')" />
      </CCol>
      <CCol v-if="!is_owner" col="1">
        <button type="button" role="button" class="input-group-text" @click="onRemove"><CIcon name="cil-x" /></button>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'BuildingContact',
  props: {
    contact: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    },
    lists: {
      type: Object,
      required: true
    },
    used: {
      type: Array,
      required: true
    }
  },
  watch: {
    contact(val) {
      this.state = val
    }
  },
  computed: {
    available_contacts() {
      const used = this.used.filter(item => item !== this.state.property_contact_id)
      const contacts = (this.lists.contacts || []).filter(item => used.indexOf(item.id) === -1)
      return contacts
    },
    available_types() {
      if (this.index) return this.lists.contact_types.filter(item => item.id !== this.CONTACT_OWNER)
      return this.lists.contact_types
    },
    is_owner() {
      return !this.state._is_new && this.state.type_id === this.CONTACT_OWNER
    }
  },
  data() {
    return {
      CONTACT_OWNER: 1, // TODO: this should come from backend and be configurable

      state: {} // ...this.contact }
    }
  },
  mounted() {
    this.state = this.contact
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      this.$emit('contactChanged', this.state, e)
    },
    onRemove() {
      this.$emit('removeContact', this.index)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text {
  height: calc(1.5em + 0.75rem + 2px);
}
</style>

<template>
  <div @keydown="onChange" @click="onChange" @change="onChange">
    <CRow v-if="!index" class="no-gutters">
      <CCol col="3" />
      <CCol col="3">{{ $t('Modules') }}</CCol>
      <CCol col="3">{{ $t('Imputations') }}</CCol>
      <CCol col="2">{{ $t('Shares') }}</CCol>
      <CCol col="1" />
    </CRow>
    <CRow class="no-gutters">
      <CCol col="3" />
      <CCol col="3">
        <AMultiSelect :horizontal="{ input: 'col-sm-12' }" :name="`shares.$each.${index}.module_id`" :disabled="disabled" v-model="state.module_id" :options="available_modules" :isValid="isValid('module_id')" :errors="getErrors('module_id')" />
      </CCol>
      <CCol col="3">
        <AMultiSelect :horizontal="{ input: 'col-sm-12' }" :name="`shares.$each.${index}.imputation_id`" :disabled="disabled" v-model="state.imputation_id" :options="lists.share_imputations" :isValid="isValid('imputation_id')" :errors="getErrors('imputation_id')" />
      </CCol>
      <CCol col="2">
        <AInput :horizontal="{ input: 'col-sm-12' }" type="number" :name="`shares.$each.${index}.index_cop`" :disabled="disabled" v-model="state.index_cop" placeholder="Value" :isValid="isValid('index_cop')" :errors="getErrors('index_cop')" />
      </CCol>
      <CCol col="1">
        <button :disabled="disabled" type="button" role="button" class="input-group-text" @click="onRemove"><CIcon name="cil-x" /></button>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'BuildingShare',
  props: {
    share: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    },
    lists: {
      type: Object,
      required: true
    },
    used: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  watch: {
    share(val) {
      this.state = val
    }
  },
  computed: {
    available_modules() {
      const used = this.used.filter(item => item !== this.state.module_id)
      const modules = (this.lists.modules || []).filter(item => used.indexOf(item.id) === -1)
      return modules
    }
  },
  data() {
    return {
      state: {} // ...this.share }
    }
  },
  mounted() {
    this.state = this.share
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      this.$emit('shareChanged', this.state, e)
    },
    onRemove() {
      this.$emit('removeShare', this.index)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text {
  height: calc(1.5em + 0.75rem + 2px);
}
</style>

<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="showModalCRUD" :centered="true" :title="$t(current_crud.title)" size="lg">
        <template v-if="showModalCRUD">
          <ModalCRUD :options="current_crud" @modalUpdated="onModalUpdated" @parentHide="showModalCRUD = false" />
        </template>
        <template #footer-wrapper><span></span></template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit building') + ` id: ${form.id}` : $t('Create building') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <CTabs variant="pills" :active-tab.sync="active_tab">
              <CTab ref="buildings_tab" :title="$t('Building')" :class="{ 'tabs-errors': tabs_errors.buildings_tab }">
                <ACard title="Basic Information">
                  <AMultiSelect name="building_type_id" label="Type" v-model="form.building_type_id" :options="lists.building_types" :isValid="isValid('building_type_id')" :errors="getErrors('building_type_id')" @select="onTypeChanged" />
                  <AMultiSelect name="invoice_marking_id" label="Mark" v-model="form.invoice_marking_id" :options="lists.invoice_markings" :isValid="isValid('invoice_marking_id')" :errors="getErrors('invoice_marking_id')" />

                  <ACheckBox label="Invoicing status" placeholder="Activar o inactivar el inmueble para la facturación" :disabled="!can_be_disabled" v-model="form.status_id" />
                  <ACheckBox label="Presupuestar" placeholder="Incluir en el derrame del presupuesto" v-model="form.is_spillable" />
                  <ACheckBox label="Interest" placeholder="Generar intereses de mora" v-model="form.generate_interest" />
                  <ACheckBox label="Electronic invoice" placeholder="Generar factura electrónica de la DIAN" v-model="form.electronic_invoice" />
                  <hr />
                  <ACheckBox label="Print" placeholder="Activar o inactivar la impresión física de la factura" v-model="form.is_printable" />
                  <ACheckBox label="Invoice to email" placeholder="Activar o inactivar el envío de facturas por correo electrónico" v-model="form.is_sendable" />
                  <hr />
                  <AInput name="code" label="Code" v-model="form.code" :isValid="isValid('code')" :errors="getErrors('code')" />
                  <AInput name="accounting_code" label="Accounting code" v-model="form.accounting_code" :isValid="isValid('accounting_code')" :errors="getErrors('accounting_code')" />

                  <AMultiSelectMaster name="property_unit_id" label="Unit" v-model="form.property_unit_id" :options="lists.property_units" :isValid="isValid('property_unit_id')" :errors="getErrors('property_unit_id')" @addElement="onAddElement('property_unit')" />
                  <AMultiSelectMaster name="property_level_id" label="Level" v-model="form.property_level_id" :options="lists.property_levels" :isValid="isValid('property_level_id')" :errors="getErrors('property_level_id')" @addElement="onAddElement('property_level')" />
                  <AMultiSelectMaster name="property_class_id" label="Class" v-model="form.property_class_id" :options="filtered_property_classes" :isValid="isValid('property_class_id')" :errors="getErrors('property_class_id')" @addElement="onAddElement('property_class')" />

                  <AMultiSelect v-if="is_soporte" :horizontal="{ input: 'col-sm-6' }" name="imputation_id" label="Imputation" v-model="form.imputation_id" :options="lists.otc_imputations" :isValid="isValid('imputation_id')" :errors="getErrors('imputation_id')" />
                  <AMultiSelectMaster name="property_cost_center_id" label="Cost center" v-model="form.property_cost_center_id" :options="lists.property_cost_centers" :isValid="isValid('property_cost_center_id')" :errors="getErrors('property_cost_center_id')" @addElement="onAddElement('property_cost_center')" />

                  <ACard title="Coefficient shares">
                    <div v-for="(share, index) of form.shares" :key="index">
                      <transition name="fade">
                        <BuildingShare ref="building_share" :disabled="!is_editable" :share="share" :index="index" :lists="lists" :used="used_modules" :validation="[`shares.$each.${index}.`, isValid, getErrors]" @shareChanged="value => $set(form.shares, index, value)" @removeShare="removeShare(index, share)" />
                      </transition>
                    </div>
                    <CCardFooter>
                      <CButton :disabled="!is_editable || !can_add_share" color="primary" class="pull-right" @click="addShare">{{ '+ ' + $t('Add share') }}</CButton>
                    </CCardFooter>
                  </ACard>

                  <hr />
                  <!--CTextarea :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }" :label="$t('Description')" v-model="form.description" /-->
                  <ATextArea label="Description" v-model="form.description" />
                  <AInput name="id_real_estate" label="Real estate ID" v-model="form.id_real_estate" :isValid="isValid('id_real_estate')" :errors="getErrors('id_real_estate')" />
                  <hr />
                  <!--CTextarea :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }" :label="$t('Invoice message')" v-model="form.invoice_message" /-->
                  <ATextArea label="Invoice message" v-model="form.invoice_message" />
                  <hr />
                  <AMultiSelectMaster :disabled="!is_editable || !!grouped" name="current_owner_id" label="Current owner" v-model="form.current_owner_id" :options="lists.contacts" :isValid="isValid('current_owner_id')" :errors="getErrors('current_owner_id')" @select="onOwnerChanged" @addElement="onAddElement('current_owner')" />
                  <CInput v-if="grouped" :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }" disabled="true" :label="$t('Grouper')" :value="grouped" />
                </ACard>
              </CTab>

              <CTab :disabled="!form.id" ref="contacts_tab" :title="$t('Contacts')" :class="{ 'tabs-errors': tabs_errors.contacts_tab }">
                <ACard title="Building contacts">
                  <div v-for="(contact, index) of form.contacts" :key="index">
                    <transition name="fade">
                      <BuildingContact ref="building_contact" :contact="contact" :index="index" :lists="lists" :used="used_contacts" :validation="[`contacts.$each.${index}.`, isValid, getErrors]" @contactChanged="value => $set(form.contacts, index, value)" @removeContact="removeContact(index, contact)" />
                    </transition>
                  </div>
                  <CCardFooter>
                    <CButton :disabled="!can_add_contact" color="primary" class="pull-right" @click="addContact">{{ '+ ' + $t('Add contact') }}</CButton>
                  </CCardFooter>
                </ACard>
              </CTab>

              <CTab v-if="!grouped" :disabled="!form.id" ref="groupings_tab" :title="$t('Grouped')" :class="{ 'tabs-errors': tabs_errors.groupings_tab }">
                <ACard title="Grouped">
                  <BuildingGrouping :grouping="form.grouping.existing" :objects="objects" icon="cil-x" @groupingAction="removeGroup" />
                </ACard>

                <ACard title="Available">
                  <BuildingGrouping :grouping="form.grouping.available" :objects="objects" icon="cil-chevron-double-up" @groupingAction="addGroup" />
                </ACard>
              </CTab>

              <!--CTab :disabled="!form.id" ref="interests_tab" :title="$t('Intereses Congelados')" :class="{ 'tabs-errors': tabs_errors.interests_tab }" /-->
            </CTabs>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import * as utils from '@/app/_utils/global-utils'
import formMixin from '@/app/views/_mixins/form-mixin'

import BuildingShare from './elements/BuildingShare'
import BuildingContact from './elements/BuildingContact'
import BuildingGrouping from './elements/BuildingGrouping'

import ContactForm from '@/app/views/admin/property/building/contacts/ContactForm'

import { DEBUG } from '@/config/config'

export default {
  name: 'BuildingForm',
  mixins: [formMixin],
  components: {
    BuildingShare,
    BuildingContact,
    BuildingGrouping
  },
  props: {
    caption: {
      type: String,
      default: 'Building id'
    }
  },
  computed: {
    is_otros() {
      const building_types = this.objects.building_types || {}
      return building_types[this.form.building_type_id] && building_types[this.form.building_type_id].name === this.TYPE_SOPORTE
    },
    is_soporte() {
      const property_classes = this.objects.property_classes || {}
      return property_classes[this.form.property_class_id] && property_classes[this.form.property_class_id].name === this.CLASS_SOPORTE
    },
    filtered_property_classes() {
      if (this.is_otros) return this.lists.property_classes.filter(item => item.name === this.CLASS_SOPORTE)
      return this.lists.property_classes.filter(item => item.name !== this.CLASS_SOPORTE)
    },

    // TODO: better names ?
    can_be_disabled() {
      if (this.periods._status !== 'FAC' && this.form.balance === 0 && this.form.index_cop === 0) return true
      return false
    },
    is_editable() {
      if (this.periods._status !== 'FAC') return true
      return false
    },

    // Shares
    can_add_share() {
      if (!this.available_modules.length) return false
      let valid = true
      for (const share of this.form.shares) {
        const index_cop = parseFloat('0' + (share.index_cop || 0))
        if (!share.module_id || !share.imputation_id || index_cop === 0) valid = false
      }
      return valid
    },
    available_modules() {
      const used = []
      this.form.shares.map(item => used.push(item.module_id))
      const modules = (this.lists.modules || []).filter(item => used.indexOf(item.id) === -1)
      return modules
    },
    used_modules() {
      const used = []
      this.form.shares.map(item => used.push(item.module_id))
      return used
    },

    // Contacts
    can_add_contact() {
      if (!this.available_contacts.length) return false
      let valid = true
      for (const contact of this.form.contacts) {
        if (!contact.property_contact_id || !contact.type_id) valid = false
      }
      return valid
    },
    available_contacts() {
      const used = []
      this.form.contacts.map(item => used.push(item.property_contact_id))
      const contacts = (this.lists.contacts || []).filter(item => used.indexOf(item.id) === -1)
      return contacts
    },
    used_contacts() {
      const used = []
      this.form.contacts.map(item => used.push(item.property_contact_id))
      return used
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper
      TYPE_SOPORTE: 'Otros Ingresos (Soporte)', // TODO: this should come from backend and be configurable
      COST_CENTER: '0', // TODO: this should come from backend and be configurable
      CLASS_UNICA: 'Única', // TODO: this should come from backend and be configurable
      CLASS_SOPORTE: 'Soporte', // TODO: this should come from backend and be configurable
      CONTACT_OWNER: 1, // TODO: this should come from backend and be configurable

      // DATA
      form: { id: 0, shares: [], contacts: [], grouping: { grouped: [], existing: [], available: [] } },
      original: {},

      // Extra
      lists: {
        building_types: [],
        property_units: [],
        property_levels: [],
        property_classes: [],
        invoice_markings: [],
        property_cost_centers: []
      },

      // Helpers
      active_tab: 0,
      grouped: false,
      objects: {},
      validators: {},
      current_crud: {},
      showModalCRUD: false,
      tabs_errors: {
        buildings_tab: false,
        contacts_tab: false,
        groupings_tab: false,
        interests_tab: false
      }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/buildings' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onAddElement(type) {
      //console.log(type)
      this.current_crud = {
        url: `admin/${type}s`, // Pluralize
        name: type,
        type: `${type}s`, // Pluralize
        field: `${type}_id`,
        title: this._humanize(type),
        modal: true,
        perPage: 5,
        fields: ['name']
      }
      if (type === 'property_class') {
        this.current_crud.url = `admin/${type}es` // Pluralize
        this.current_crud.type = `${type}es` // Pluralize
        this.current_crud.lname = `${type}es` // Pluralize
      }
      if (type === 'current_owner') {
        //this.current_crud.url = `admin/building_contacts`
        this.current_crud.type = 'contacts'
        this.current_crud.field = 'current_owner'
        this.current_crud.master = ContactForm
      }
      this.showModalCRUD = true
    },
    onModalUpdated(items) {
      // TODO: Improve this
      if (items.length) {
        if (items.length === 2 && items[0] === '_single') {
          this.lists[this.current_crud.type].push({ id: items[1].id, name: items[1].name })
        } else {
          this.lists[this.current_crud.type] = items
        }

        // Force refresh list
        const value = this.form[this.current_crud.field]
        this.form[this.current_crud.field] = ''
        this.$nextTick(() => {
          this.form[this.current_crud.field] = value
        })
      }
    },
    onTypeChanged(type) {
      if (type.name === this.TYPE_SOPORTE && !this.is_soporte) this.$set(this.form, 'property_class_id', '')
    },
    onOwnerChanged(contact) {
      const old_owner = this.form.contacts.findIndex(item => item.type_id === this.CONTACT_OWNER)
      if (old_owner >= 0) {
        this.$set(this.form, 'remove_contacts', [...(this.form.remove_contacts || []), this.form.contacts[old_owner].id])
        this.form.contacts.splice(old_owner, 1)
      }

      if (contact.id) {
        const new_owner = this.form.contacts.findIndex(item => item.property_contact_id === contact.id)
        if (new_owner >= 0) {
          this.form.contacts[new_owner].type_id = this.CONTACT_OWNER
        } else {
          this.form.contacts.unshift({ id: 0, property_contact_id: contact.id, type_id: this.CONTACT_OWNER, _is_new: false })
        }
      }

      this.form.contacts.sort((a, b) => a.type_id - b.type_id)
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.building ? data.building : { id: 0 }
      this.lists = data._lists || {}

      this.parseDefaults()
      this.parseView()
      this.parseShares()
      this.parseContacts()
      this.parseGrouping()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Helpers
    setObjects() {
      this.objects.building_types = {}
      for (const building_type of this.lists.building_types || []) {
        this.objects.building_types[building_type.id] = building_type
      }
      this.objects.property_classes = {}
      for (const property_class of this.lists.property_classes || []) {
        this.objects.property_classes[property_class.id] = property_class
      }

      this.objects.property_units = {}
      for (const property_unit of this.lists.property_units || []) {
        this.objects.property_units[property_unit.id] = property_unit
      }
      this.objects.contacts = {}
      for (const contact of this.lists.contacts || []) {
        this.objects.contacts[contact.id] = contact
      }
    },

    // Parsing

    parseDefaults() {
      if (!this.form.id) {
        this.$set(this.form, 'property_class_id', utils.search(this.lists.property_classes, 'name', this.CLASS_UNICA).id)
        this.$set(this.form, 'property_cost_center_id', utils.search(this.lists.property_cost_centers, 'name', this.COST_CENTER).id)
      }
      this.setObjects()
    },

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Building']: options })
    },
    parseShares() {
      if (!this.form.shares.length) this.addShare()
    },
    parseContacts() {
      for (const idx in this.form.contacts) {
        const contact = this.form.contacts[idx].contact
        //if (this.form.contacts[idx].contact) this.form.contacts[idx] = { ...this.form.contacts[idx].contact, ...this.form.contacts[idx], _is_new: false }
        if (contact.contact) {
          this.form.contacts[idx] = {
            type_id: contact.contact.type_id,
            property_contact_id: contact.contact.property_contact_id,
            ...this.form.contacts[idx],
            _is_new: false
          }
        }
      }
      //this.form.contacts.map(contact => (contact = { ...contact.pivot, ...contact, _is_new: false }))
      if (!this.form.contacts.length) this.addContact()
    },
    parseGrouping() {
      this.grouped = false
      this.form.grouping = this.form.grouping || { grouped: [], existing: [], available: [] }

      const grouper = this.form.grouper || {}
      //if (!this.form.grouping.grouped.length) {
      if (grouper) {
        if (grouper.building_id === grouper.grouper_id) {
          this.form.grouping.existing.forEach(item => (item.owner_id = this.form.current_owner_id))
          this.form.grouping.available.forEach(item => (item.owner_id = this.form.current_owner_id))
        } else {
          //this.grouped = this.form.grouping.grouped[0].description + ' - ' + this.form.grouping.grouped[0].code
          this.grouped = this.objects.building_types[grouper.grouper.building_type_id].name + ' ' + this.objects.property_units[grouper.grouper.property_unit_id].name + ' - ' + grouper.grouper.code
        }
      }
    },

    // Shares

    addShare() {
      if (this.form.shares.length < 10) {
        // TODO: remove this
        // TODO: validate available modules
        if (this.can_add_share) this.form.shares.push({ id: 0 })
      }
      if (this.form.shares.length === 1) this.validateShares()
    },
    removeShare(index, share) {
      if (share.id) this.form.remove_shares = [...(this.form.remove_shares || []), ...[share.id]]
      this.form.shares.splice(index, 1)
      if (this.form.shares.length === 0) this.addShare() // this.removeValidator('shares')
    },
    validateShares() {
      this.$nextTick(() => {
        if (this.validators?.extra?.shares) this.injectValidator('shares', 0)
      })
    },

    // Contacts

    addContact() {
      if (this.form.contacts.length < 10) {
        // TODO: remove this
        // TODO: validate available modules
        if (this.can_add_contact) this.form.contacts.push({ id: 0, pivot: {}, _is_new: true })
      }
      //if (this.form.contacts.length === 1) this.validateContacts()
    },
    removeContact(index, contact) {
      if (contact.id) this.form.remove_contacts = [...(this.form.remove_contacts || []), ...[contact.id]]
      this.form.contacts.splice(index, 1)
      if (this.form.contacts.length === 0) this.addContact() // this.removeValidator('contacts')
    },
    /*validateContacts() {
      this.$nextTick(() => {
        if (this.validators?.extra?.contacts) this.injectValidator('contacts', 0)
      })
    },*/

    // Grouping

    addGroup(index) {
      this.form.grouping.existing.push(this.form.grouping.available[index])
      this.form.grouping.available.splice(index, 1)
    },
    removeGroup(index) {
      this.form.grouping.available.push(this.form.grouping.existing[index])
      this.form.grouping.existing.splice(index, 1)
    },

    // SUBMIT

    beforeSubmit() {
      if (!this.is_soporte) delete this.form.imputation_id
    },

    submit() {
      this.setErrors({})
      Object.keys(this.tabs_errors).forEach(key => (this.tabs_errors[key] = false))
      this.$v.form.$touch()

      const alert = `There was an error ${this.form.id ? 'saving' : 'creating'} the property.`

      if (this.$v.form.$error) {
        this.locateErrors(alert)
        return
      }

      this.beforeSubmit()

      const self = this
      self.form._is_soporte = self.is_soporte
      self.$http[self.form.id ? 'put' : 'post']('admin/buildings', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/property/buildings/${response.data.building.id}/edit`).catch(() => {})
          self.showAlert(`Building ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
            self.locateErrors(alert)
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            self.showAlert(alert, 'danger')
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(alert, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>

<style lang="scss">
.tabs-errors {
  .nav-link:after {
    //content: '❗';
    content: '🔺';
  }
}
.multiselect {
  .multiselect__content-wrapper {
    width: auto;
    min-width: 100%;
  }
  .multiselect__single {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>

<template>
  <CDataTable :items="grouping" :key="'A' + grouping.length" :fields="fields">
    <template #building_type_id="{item}">
      <td>{{ objects.building_types[item.building_type_id].name }}</td>
    </template>
    <template #property_unit_id="{item}">
      <td>{{ objects.property_units[item.property_unit_id].name }}</td>
    </template>
    <template #owner="{item}">
      <td>{{ objects.contacts[item.owner_id].name }}</td>
    </template>
    <template #options="{index}">
      <td>
        <CButton @click="actionGroup(index)"><CIcon :name="icon"/></CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  name: 'BuildingGrouping',
  props: {
    grouping: {
      type: Array,
      default: () => ['', false, false]
    },
    objects: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [
        { key: 'code', label: this.$t('Code') },
        { key: 'building_type_id', label: this.$t('Building type') },
        { key: 'property_unit_id', label: this.$t('Building unit') },
        { key: 'owner', label: this.$t('Current owner') },
        { key: 'options', label: this.$t('Options') }
      ]
    }
  },
  methods: {
    actionGroup(index) {
      this.$emit('groupingAction', index)
    }
  }
}
</script>
